import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";
import CardBlog from "../components/CardBlog";

import IconFacebook from "../images/icons/social/facebook-white.svg";
import IconTwitter from "../images/icons/social/twitter-white.svg";
import IconLinkedIn from "../images/icons/social/linkedin-white.svg";

const ThankYouPage1 = ({ data, location: { pathname } }) => {
  const latestArticle = data.recentArticles?.edges
    ? data.recentArticles?.edges[0]?.node
    : undefined;

  return (
    <Layout
      hideFooter
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: "Thank you!",
        },
        path: pathname,
      }}
    >
      <div className="thank-you-page">
        <ThankYouWrapper
          title="Thank you for your purchase!"
          description="Here's the link to the ebook: AI in UX - The Ultimate Guide:"
          middleBlock={
            <div className="pt-0 middle-block">
              <p className="lead mt-3 middle-description">
                <a href="https://adamfard.notion.site/AI-in-UX-The-Ultimate-Guide-1dd415e178b1460490896920ca5c4d21" target="_blank" rel="noopener noreferrer">
                  AI in UX - The Ultimate Guide
                </a>
              </p>
            </div>
          }
          verticalLayout={true}
          article={<CardBlog key={latestArticle.id} cardData={latestArticle} />}
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage1;

export const query = graphql`
  query ThankYouDownloadEbookQuery {
    recentArticles: allDatoCmsArticle(
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 1024, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
